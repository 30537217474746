import { Pusher } from '@habralab/pusher';
import { ref } from 'vue';

import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

/**
 * @typedef {import('@habralab/pusher/dist/index').CareerPusherEventMap} CareerPusherEventMap
 */

async function JWTTokenGetter() {
  const response = await cancellableRequest({
    method: 'GET',
    url: `${apiUrlV1}/users/pusher_token`,
  });

  return response.data.token;
}

function getPusherURI() {
  if (window.location.hostname === 'career.habr.com')
    return 'wss://pusher.habr.com:8443/ws/career';

  return 'wss://rc.pusher.habratest.net/ws/career';
}

/**
 * @type {Pusher<CareerPusherEventMap> | null}
 */
let pusher = null;

export function usePusher() {
  const isConnected = ref(false);
  if (pusher || !window.app.isUserLoggedIn) return { pusher, isConnected };

  pusher = new Pusher({
    uri: getPusherURI(),
    JWTTokenGetter,
  });

  pusher.on('connected', (connected) => {
    isConnected.value = connected;
  });

  pusher.connect();

  return {
    pusher,
    isConnected,
  };
}
